import * as React from "react";
import { cva, type VariantProps } from "class-variance-authority";
import { Loader2 } from "lucide-react";

import { cn } from "@/lib/utils";

const buttonVariants = cva(
  "inline-flex items-center justify-center rounded-md text-base transition-colors focus-visible:outline-none disabled:pointer-events-none disabled:opacity-50",
  {
    variants: {
      variant: {
        default:
          "inline-flex items-center justify-center gap-3 bg-white font-semibold leading-none text-neutral-950",
        outline:
          "inline-flex items-center justify-center gap-3 border border-white font-semibold leading-none text-primary",
        rounded:
          "inline-flex items-center justify-center gap-3 rounded-full border border-white leading-none text-primary",
        text: "inline-flex items-center justify-center gap-3 !px-0 !py-0 leading-none text-primary",
        pill: "pill-button inline-flex items-center justify-center gap-3 rounded-full font-semibold leading-none",
        "pill-selected":
          "pill-button-selected inline-flex items-center justify-center gap-3 rounded-full font-semibold leading-none",
        "pill-2":
          "pill-2-button inline-flex items-center justify-center gap-3 rounded-full leading-none",
        "pill-2-selected":
          "pill-2-button-selected inline-flex items-center justify-center gap-3 rounded-full leading-none",
        "3d-gold":
          "rounded-full bg-primary-gold p-2 !px-8 font-semibold shadow-[0_9px_0_#AA7650] transition-all ease-out hover:translate-y-1 hover:shadow-[0_4px_0px_#AA7650]",
        "3d-white":
          "rounded-full bg-white p-2 !px-8 font-semibold shadow-[0_9px_0_#B0B0B0] transition-all ease-out hover:translate-y-1 hover:shadow-[0_4px_0px_#B0B0B0]",
      },
      size: {
        default: "px-6 py-3",
        sm: "px-3 py-2 text-sm",
        lg: "px-8 py-6 text-lg",
        icon: "h-10 w-10 rounded-full leading-none",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  }
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  loading?: boolean;
  loadingClassName?: string;
  v2Effect?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      variant,
      size,
      loading,
      children,
      disabled,
      v2Effect,
      loadingClassName,
      ...props
    },
    ref
  ) => {
    return (
      <button
        className={cn(
          buttonVariants({ variant, size, className }),
          v2Effect &&
            "h-16 rounded-full bg-primary-gold p-2 !px-8 shadow-[0_9px_0_#AA7650] transition-all ease-out hover:translate-y-1 hover:shadow-[0_4px_0px_#AA7650]"
        )}
        ref={ref}
        disabled={loading || disabled}
        {...props}
      >
        {children}
        {loading && (
          <Loader2
            className={cn("mr-2 h-4 w-4 animate-spin", loadingClassName)}
          />
        )}
      </button>
    );
  }
);
Button.displayName = "Button";

export default Button;
